import { TableControls, TableHead, TableStatusInfo } from 'components/molecules';
import styled, { css } from 'styled-components';

// TODO: move gradients into theme const

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.24) 0%, rgba(18, 16, 17, 0.4) 86.04%),
    #121011;

  ${({ theme: { button } }) => css`
    border-bottom: 1px solid ${button.primary};
  `}
`;

export const TableHeadStyled = styled(TableHead)`
  max-width: 1280px;
  width: 100%;
  padding: 0 64px;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 445px 675px;
  column-gap: 32px;
  max-width: 1280px;
  width: 100%;
  margin: 32px auto 0;
  padding: 0 64px;
`;

export const ControlsWrapper = styled.div``;

export const TableStatusInfoStyled = styled(TableStatusInfo)``;

export const TableControlsStyled = styled(TableControls)`
  margin-top: 24px;
`;

export const CloseNotificationWrapper = styled.div`
  margin-top: 32px;
`;

export const ViewWrapper = styled.div`
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  overflow: hidden;

  ${({ theme: { background } }) => css`
    border-color: ${background.secondaryDefault};
    background-color: ${background.primaryContrast};
  `}
`;

export const HistoryResult = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  z-index: 2;
`;
export const ConnectionStatusWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 2;
`;

export const BallWrapper = styled.div`
  width: 24px;
  height: 24px;
  &:not(:first-of-type) {
    margin-left: 8px;
  }
`;
