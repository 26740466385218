import React, { useCallback, useEffect, useMemo, memo } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { webSocketService } from 'services/WebSocketService';
import { Ball } from 'components/atoms';
import {
  PopupVerification,
  PopupVerificationTypes,
  PopupChangeResult,
  DrawResult,
  PopupClosingTable,
  Alert,
  ConnectionStatusLabel,
} from 'components/molecules';

import { getGameData, getGameMachineConnection, getSocketDisconnected } from 'core/selectors';
import { useModal } from 'hooks/useModal';
import { useTimer } from 'hooks/useTimer';
import {
  cancelRoundAction,
  changeDealerAction,
  confirmResultAction,
  startDrawAction,
  startRoundAction,
  tableCloseAction,
  tableCloseVerifyAction,
  changeResultVerifyAction,
  changeResultAction,
  connectToWebSocketAction,
  tableClosedAction,
  disconnectFromWebSocketAction,
  clearGameDataAction,
  clearSelectedTableToStore,
  clearSagaChannels,
  changeResultFailureAction,
} from 'data/reducers';
import { TableStates, ResultBall, ToastTypes, ICustomError } from 'types';

import { IPopupVerificationValues } from 'components/molecules/PopupVerification/PopupVerificationContainer';
import { TablesClosingStatus } from 'constants/tables';
import { ballOptions } from 'constants/common';

import { tableClosingOptionsMock } from 'mocks/tableClosingOptionsMock';
import {
  useRequestError,
  useRequestLoading,
  useOnRequestSuccess,
  useButtonControls,
  useStreamStatus,
} from 'hooks';

import { getAuthSelector } from 'core/selectors/authSelectors';
import { JPWinner } from 'components/molecules/DrawResult/styles';
import {
  BallWrapper,
  CloseNotificationWrapper,
  ContentWrapper,
  ControlsWrapper,
  HistoryResult,
  TableControlsStyled,
  TableHeader,
  TableHeadStyled,
  TableStatusInfoStyled,
  ViewWrapper,
  Wrapper,
  ConnectionStatusWrapper,
} from './styles';

export const TablePage = memo(() => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const auth = useSelector(getAuthSelector);

  const { id: urlTableId } = useParams();

  const {
    isShown: isChangeDealerPopupShown,
    open: openChangeDealerPopup,
    close: closeChangeDealerPopup,
  } = useModal();
  const { isShown: isCancelRoundPopupShown, toggle: toggleCancelRoundPopup } = useModal();
  const { isShown: isCloseTableVerifyPopupShown, toggle: toggleCloseTableVerifyPopup } = useModal();
  const { isShown: isCloseTablePopupShown, toggle: toggleCloseTablePopup } = useModal();
  const { isShown: isChangeResultVerifyPopupShown, toggle: toggleChangeResultVerifyPopup } =
    useModal();
  const { isShown: isChangeResultPopupShown, toggle: toggleChangeResultPopup } = useModal();

  const isGameMachineConnected = useSelector(getGameMachineConnection);

  const isSocketDisconnected = useSelector(getSocketDisconnected);

  const {
    table_id: tableId,
    gameId,
    dealer,
    tableState,
    statusText,
    round: { timeValue, isFinished, drawResults, winner, timerLeft },
    closed,
    changeResult,
    toastItem,
    stream_low,
  } = useSelector(getGameData);

  const { startBettingTimer, startDrawTimer, reloadBettingTimer, reloadDrawTimer } = useTimer();

  const { isStreamOn } = useStreamStatus(stream_low);

  const {
    enabledButtons,
    isChangeDealerEnabled,
    onOpenRoundClick,
    onStartDrawClick,
    onConfirmResultClick,
  } = useButtonControls();

  const { error: dealerChangedError, clearError: dealerChangedClearError } =
    useRequestError(changeDealerAction);
  const { error: cancelRoundError, clearError: cancelRoundClearError } =
    useRequestError(cancelRoundAction);
  const { error: changeResultVerifyError, clearError: changeResultVerifyClearError } =
    useRequestError(changeResultVerifyAction);
  const { error: changeResultError, clearError: changeResultClearError } =
    useRequestError(changeResultAction);
  const { error: closeTableError, clearError: closeTableClearError } =
    useRequestError(tableCloseVerifyAction);
  const { error: connectToWebSocketError, clearError: connectToWebSocketClearError } =
    useRequestError(connectToWebSocketAction);

  const changeDealerLoading = useRequestLoading(changeDealerAction);
  const cancelRoundLoading = useRequestLoading(cancelRoundAction);
  const closeTableVerifyLoading = useRequestLoading(tableCloseVerifyAction);
  const changeResultVerifyLoading = useRequestLoading(changeResultVerifyAction);
  const openRoundLoading = useRequestLoading(startRoundAction);
  const startDrawLoading = useRequestLoading(startDrawAction);
  const confirmResultLoading = useRequestLoading(confirmResultAction);
  const closeTableLoading = useRequestLoading(tableCloseAction);
  const changeResultLoading = useRequestLoading(changeResultAction);

  useOnRequestSuccess(tableClosedAction, () => {
    navigate('/tables', { replace: true });
    dispatch(disconnectFromWebSocketAction());
    dispatch(clearSagaChannels());
    dispatch(clearGameDataAction());
    dispatch(clearSelectedTableToStore());
  });

  const onChangeDealer = useCallback(
    ({ id }: { id: string }) => {
      dispatch(changeDealerAction({ dealerId: id }));
    },
    [dispatch],
  );

  const onChangeResultClick = useCallback(() => {
    toggleChangeResultVerifyPopup();
  }, [toggleChangeResultVerifyPopup]);

  const onCancelRoundClick = useCallback(() => {
    toggleCancelRoundPopup();
  }, [toggleCancelRoundPopup]);

  const onCancelRoundSubmit = useCallback(
    (values: IPopupVerificationValues) => {
      dispatch(cancelRoundAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  const onCloseTableVerifySubmit = useCallback(
    (values: { id: string }) => {
      dispatch(tableCloseVerifyAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  const onChangeResultVerifySubmit = useCallback(
    (values: { id: string }) => {
      dispatch(changeResultVerifyAction({ shiftManagerId: values.id }));
    },
    [dispatch],
  );

  const onCloseTableSubmit = useCallback(
    (value: TablesClosingStatus) => {
      if (closed?.verified && closed?.shiftManagerId) {
        dispatch(tableClosedAction());
        dispatch(tableCloseAction({ shiftManagerId: closed.shiftManagerId, closingStatus: value }));
      }
    },
    [dispatch, closed],
  );

  const onChangeResultSubmit = useCallback(
    (value: string) => {
      if (changeResult?.verified && changeResult?.shiftManagerId) {
        dispatch(
          changeResultAction({ shiftManagerId: changeResult.shiftManagerId, winner: value }),
        );
      }
      if (!changeResult?.verified || !changeResult?.shiftManagerId) {
        dispatch(changeResultFailureAction({ status: 601, message: 'NotAvailableAction' }));
      }
    },
    [dispatch, changeResult],
  );

  useEffect(() => {
    closeChangeDealerPopup();
  }, [dealer, closeChangeDealerPopup]);

  useEffect(() => {
    if (isCancelRoundPopupShown && tableState === TableStates.RoundCanceled) {
      toggleCancelRoundPopup();
    }
  }, [tableState, isCancelRoundPopupShown, toggleCancelRoundPopup]);

  useEffect(() => {
    if (tableState === TableStates.BettingTimeStarted) {
      startBettingTimer();
    }
    if (tableState === TableStates.DrawStarted) {
      startDrawTimer();
    }
  }, [tableState, startBettingTimer, startDrawTimer]);

  useEffect(() => {
    if (
      tableState === TableStates.BettingTimeFinished ||
      tableState === TableStates.RoundCanceled
    ) {
      reloadBettingTimer();
    }
    if (tableState === TableStates.DrawFinished || tableState === TableStates.RoundCanceled) {
      reloadDrawTimer();
    }
  }, [reloadBettingTimer, reloadDrawTimer, tableState]);

  useEffect(() => {
    if (changeResult?.verified) {
      toggleChangeResultVerifyPopup();
      toggleChangeResultPopup();
    }
  }, [changeResult?.verified, toggleChangeResultVerifyPopup, toggleChangeResultPopup]);

  useEffect(() => {
    if (closed?.verified) {
      toggleCloseTableVerifyPopup();
      toggleCloseTablePopup();
    }
  }, [closed?.verified, toggleCloseTableVerifyPopup, toggleCloseTablePopup]);

  useEffect(() => {
    if (closed?.verified && closed.isInProgress) {
      toggleCloseTablePopup();
    }
  }, [closed?.verified, closed?.isInProgress, toggleCloseTablePopup]);

  useEffect(() => {
    if (changeResult?.verified && changeResult.isInProgress && changeResult.isChanged) {
      toggleChangeResultPopup();
    }
  }, [
    changeResult?.verified,
    changeResult?.isInProgress,
    changeResult?.isChanged,
    toggleChangeResultPopup,
  ]);

  useEffect(() => {
    if (isChangeDealerPopupShown) {
      dealerChangedClearError();
    }
    if (isCancelRoundPopupShown) {
      cancelRoundClearError();
    }
    if (isCloseTableVerifyPopupShown) {
      closeTableClearError();
    }
    if (isChangeResultVerifyPopupShown) {
      changeResultVerifyClearError();
    }
    if (isChangeResultPopupShown) {
      changeResultClearError();
    }
  }, [
    isChangeDealerPopupShown,
    isCancelRoundPopupShown,
    isCloseTableVerifyPopupShown,
    isChangeResultVerifyPopupShown,
    isChangeResultPopupShown,
    cancelRoundClearError,
    closeTableClearError,
    changeResultClearError,
    changeResultVerifyClearError,
    dealerChangedClearError,
  ]);

  useEffect(() => {
    const error = connectToWebSocketError as ICustomError;
    if (error?.message === 'Unauthorized' || error?.status === 404) {
      connectToWebSocketClearError();
      navigate(`/not_found`, { replace: true });
    }
  }, [connectToWebSocketError, connectToWebSocketClearError, navigate]);

  useEffect(() => {
    if (!webSocketService.isConnected() && urlTableId) {
      dispatch(clearSagaChannels());
      dispatch(
        connectToWebSocketAction({
          tableId: urlTableId,
          token: sessionStorage.getItem('access_to_ws_token')!,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resultLogItems = useMemo(
    () =>
      drawResults.map((draw, index) => (
        <BallWrapper key={`${draw}-${index}`}>
          <Ball value={draw as ResultBall} />
        </BallWrapper>
      )),
    [drawResults],
  );

  return (
    <Wrapper>
      {isChangeDealerPopupShown
        ? createPortal(
            <PopupVerification
              title="Change dealer"
              type={PopupVerificationTypes.Dealer}
              error={dealerChangedError}
              loading={changeDealerLoading}
              clearError={dealerChangedClearError}
              onCancel={closeChangeDealerPopup}
              onSubmit={onChangeDealer}
            />,
            document.body,
          )
        : null}
      {isCancelRoundPopupShown
        ? createPortal(
            <PopupVerification
              title="Cancel round"
              type={PopupVerificationTypes.ShiftManager}
              error={cancelRoundError}
              loading={cancelRoundLoading}
              clearError={cancelRoundClearError}
              onCancel={toggleCancelRoundPopup}
              onSubmit={onCancelRoundSubmit}
            />,
            document.body,
          )
        : null}
      {isCloseTableVerifyPopupShown
        ? createPortal(
            <PopupVerification
              title="Close table"
              error={closeTableError}
              loading={closeTableVerifyLoading}
              type={PopupVerificationTypes.ShiftManager}
              clearError={closeTableClearError}
              onCancel={toggleCloseTableVerifyPopup}
              onSubmit={onCloseTableVerifySubmit}
            />,
            document.body,
          )
        : null}
      {isChangeResultVerifyPopupShown
        ? createPortal(
            <PopupVerification
              title="change result"
              error={changeResultVerifyError}
              loading={changeResultVerifyLoading}
              type={PopupVerificationTypes.ShiftManager}
              clearError={changeResultVerifyClearError}
              onCancel={toggleChangeResultVerifyPopup}
              onSubmit={onChangeResultVerifySubmit}
            />,
            document.body,
          )
        : null}
      {isCloseTablePopupShown
        ? createPortal(
            <PopupClosingTable
              options={tableClosingOptionsMock}
              loading={closeTableLoading}
              onClose={toggleCloseTablePopup}
              onSubmit={onCloseTableSubmit}
            />,
            document.body,
          )
        : null}
      {isChangeResultPopupShown
        ? createPortal(
            <PopupChangeResult
              error={changeResultError}
              options={ballOptions}
              loading={changeResultLoading}
              onClose={toggleChangeResultPopup}
              onSubmit={onChangeResultSubmit}
            />,
            document.body,
          )
        : null}
      <TableHeader>
        <TableHeadStyled
          tableId={tableId}
          gameId={gameId}
          dealerName={dealer?.nickname}
          isChangeDealerDisabled={!isChangeDealerEnabled}
          onChangeDealerClick={openChangeDealerPopup}
          onCloseTableClick={toggleCloseTableVerifyPopup}
          stage={auth?.stage}
          dateTimer={auth?.countdownJackpot}
        />
      </TableHeader>
      <ContentWrapper>
        <ControlsWrapper>
          <TableStatusInfoStyled
            timeValue={timeValue || timerLeft?.bettingTimeLeft || timerLeft?.drawTimeLeft}
            isFinished={isFinished}
            statusText={statusText}
            status={tableState}
          />
          <TableControlsStyled
            onOpenRoundClick={onOpenRoundClick}
            openRoundLoading={openRoundLoading}
            onStartDrawClick={onStartDrawClick}
            startDrawLoading={startDrawLoading}
            onConfirmResultClick={onConfirmResultClick}
            confirmResultLoading={confirmResultLoading}
            onChangeResultClick={onChangeResultClick}
            onCancelRoundClick={onCancelRoundClick}
            enabledButtons={enabledButtons}
          />
          {toastItem ? (
            <CloseNotificationWrapper>
              <Alert variant={toastItem.type ? toastItem.type : ToastTypes.WARNING} showIcon>
                {toastItem.value.text}
              </Alert>
            </CloseNotificationWrapper>
          ) : null}
        </ControlsWrapper>
        <ViewWrapper>
          {auth?.sumJackpotUserWon && (
            <JPWinner>
              Jackpot drawn{' '}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 12C14.7625 12 17 9.7625 17 7C17 4.2375 14.7625 2 12 2C9.2375 2 7 4.2375 7 7C7 9.7625 9.2375 12 12 12ZM12 14.5C8.6625 14.5 2 16.175 2 19.5V20.75C2 21.4375 2.5625 22 3.25 22H20.75C21.4375 22 22 21.4375 22 20.75V19.5C22 16.175 15.3375 14.5 12 14.5Z"
                  fill="#FFF7EB"
                />
              </svg>
              {auth?.winnersListJackpot?.length}
            </JPWinner>
          )}
          {winner ? <DrawResult value={winner} /> : null}
          <ConnectionStatusWrapper>
            <ConnectionStatusLabel isConnected={isStreamOn} statusText="STREAM" />
            <ConnectionStatusLabel
              isConnected={!isSocketDisconnected ? isGameMachineConnected : !isSocketDisconnected}
              statusText="MACHINE"
            />
            <ConnectionStatusLabel isConnected={!isSocketDisconnected} statusText="SERVER" />
          </ConnectionStatusWrapper>

          {resultLogItems.length ? <HistoryResult>{resultLogItems}</HistoryResult> : null}
        </ViewWrapper>
      </ContentWrapper>
    </Wrapper>
  );
});
