import React from 'react';
import * as Icons from './Icons';

export enum IconNames {
  Person = 'Person',
  People = 'People',
  Power = 'Power',
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  SmartRecord = 'SmartRecord',
  Replay = 'Replay',
  Clear = 'Clear',
  CloseEye = 'CloseEye',
  OpenEye = 'OpenEye',
  ArrowRight = 'ArrowRight',
  NoAvailable = 'NoAvailable',
  BallBackground = 'BallBackground',
  BallBlue = 'BallBlue',
  BallRed = 'BallRed',
  BallGold = 'BallGold',
  BallUndefined = 'BallUndefined',
  Warning = 'Warning',
  LoadingIndicator = 'LoadingIndicator',
  NoAvailablePage = 'NoAvailablePage',
}

export interface IProps {
  name: IconNames;
  onClick?: () => void;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = ({ name, onClick, className }: IProps) => {
  const IconComponent = IconsMap[name];

  return <IconComponent onClick={onClick} className={className} />;
};
